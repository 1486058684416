.special-dish-content { padding-block: 70px; }
.text-center { text-align: center; }
.img-cover { width: 100%; height: 100%; object-fit: cover; }
.special-dish-content { padding-block: 70px; }
.bg-black-10 { background-color: var(--smoky-black-2); }
.container { padding-inline: 16px; }
.abs-img { position: absolute; }
.section-subtitle {
  position: relative;
  color: var(--gold-crayola);
  font-weight: var(--weight-bold);
  text-transform: uppercase;
  letter-spacing: var(--letterSpacing-2);
  margin-block-end: 12px;
}
/* More styles */

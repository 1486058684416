.section-subtitle {
  position: relative;
  color: var(--gold-crayola);
  font-weight: var(--weight-bold);
  text-transform: uppercase;
  letter-spacing: var(--letterSpacing-2);
  margin-block-end: 12px;
}
.about .section-text {
  margin-block: 15px 30px;
}
.text-center {
  text-align: center;
}
.container {
  padding-inline: 16px;
}
.about-content {
  padding-inline-end: 90px;
}
/* More styles */

.w-100 {
  position: relative;
  border-radius: 10px; /* Optional: You can round the corners if you like */
  overflow: hidden; /* Ensures the fading effect stays within the image boundaries */
}

.w-100::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    transparent 10%,
    rgba(255, 255, 255, 0.3) 20%,
    rgba(255, 255, 255, 0.3) 80%,
    transparent 90%
  );
  pointer-events: none; /* Ensures the fading overlay doesn't interfere with click events on the image */
}

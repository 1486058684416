.section-subtitle {
    position: relative;
    color: var(--gold-crayola);
    font-weight: var(--weight-bold);
    text-transform: uppercase;
    letter-spacing: var(--letterSpacing-2);
    margin-block-end: 12px;
  }
  .service .section-title { margin-block-end: 16px; }
  .bg-black-10 { background-color: var(--smoky-black-2); }
  .text-center { text-align: center; }
  .container { padding-inline: 16px; }
  /* More styles */
  
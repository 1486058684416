.section-subtitle {
    position: relative;
    color: var(--gold-crayola);
    font-weight: var(--weight-bold);
    text-transform: uppercase;
    letter-spacing: var(--letterSpacing-2);
    margin-block-end: 12px;
  }
  .features .section-title { margin-block-end: 40px; }
  .text-center { text-align: center; }
  .container { padding-inline: 16px; }
  .label-2 { font-size: var(--fontSize-label-2); }
  .headline-1,
  .headline-2,
  .title-1,
  .title-2,
  .title-3,
  .title-4 {
    color: var(--white);
    font-family: var(--fontFamily-forum);
    font-weight: var(--weight-regular);
    line-height: var(--lineHeight-2);
  }
  .section-title { margin-block-end: 16px; }
  .shape {
    display: none;
    position: absolute;
    max-width: max-content;
    z-index: -1;
  }
  
.footer-top {
  margin-block-end: 70px;
}
.has-bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.text-center {
  text-align: center;
}
.container {
  padding-inline: 16px;
}
.grid-list {
  display: grid;
  gap: 40px;
}
.footer-brand {
  position: relative;
  padding: 50px 40px;
  background: var(--smoky-black-1) url("../../../public/assets/images/footer-form-bg.png");
  background-position: center top;
  background-repeat: repeat;
}
.has-before,
.has-after {
  position: relative;
  z-index: 1;
}
.body-4 {
  font-size: var(--fontSize-body-4);
}
.contact-link {
  margin-block: 6px;
  transition: var(--transition-1);
}
.input-wrapper {
  position: relative;
}
.icon-wrapper {
  position: relative;
  margin-block-end: 20px;
}
.input-field {
  background-color: var(--eerie-black-2);
  color: var(--white);
  height: 56px;
  padding: 10px;
}
.book-table-btn {
  position: fixed;
  bottom: 80px; /* Adjust so it's above the back to top button */
  right: 20px;
  background-color: var(--gold-crayola);
  width: 50px; /* Adjust if necessary */
  height: 50px; /* Adjust if necessary */
  border-radius: var(--radius-circle);
  display: grid;
  place-items: center;
  box-shadow: var(--shadow-1);
  transition: var(--transition-1);
  z-index: 4;
}

.book-table-btn img {
  width: 100%; /* Ensures the image takes the full width of the button */
  height: auto;
}

/* Adjust hover/focus styles as needed */
.book-table-btn:is(:hover, :focus-visible) {
  background-color: var(--white);
  color: var(--gold-crayola);
}

/* More styles */

.section-subtitle {
  position: relative;
  color: var(--gold-crayola);
  font-weight: var(--weight-bold);
  text-transform: uppercase;
  letter-spacing: var(--letterSpacing-2);
  margin-block-end: 12px;
}

.menu .section-title {
  margin-block-end: 40px;
}

.container {
  padding-inline: 16px;
}

.text-center {
  text-align: center;
}

.label-2 {
  font-size: var(--fontSize-label-2);
}

.price {
  font-size: var(--fontSize-label-1);
  color: var(--gold-crayola);
  font-family: "Roboto, sans-serif;";
}

.headline-1,
.headline-2,
.title-1,
.title-2,
.title-3,
.title-4 {
  color: var(--white);
  font-family: var(--fontFamily-forum);
  font-weight: var(--weight-regular);
  line-height: var(--lineHeight-2);
}

.menu-item-title {
  color: var(--white);
  font-family: "Playfair Display", serif !important;
  font-weight: var(--weight-regular);
  line-height: var(--lineHeight-2);
}

.img-holder-menu {
  width: 100px; /* Adjust width as needed */
  height: 100px; /* Adjust height as needed */
  overflow: hidden;
}

.img-fit {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This will cover the area without stretching the image */
}

/* New Styles */
.menu-card-link {
  text-decoration: none; /* Remove default underline */
  color: inherit; /* Inherit text color from parent */
  display: block; /* Make the link fill the entire list item */
}

.menu-card {
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.menu-card:hover,
.menu-card:focus {
  transform: scale(1.05); /* Slightly scale up on hover/focus */
  cursor: pointer; /* Change cursor to indicate clickable */
}

/* Enhance hover effect for better user interaction */
.hover:card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow to indicate elevation */
}

/* SpecialEvent.css */
.special-event-page {
  color: var(--white);
  background-color: var(--eerie-black-1); /* or any other color you prefer */
  padding: 5rem 0; /* Adjust padding as needed */
}

/* Highlighted header section */
.event-header {
  background-color: var(--smoky-black-1); /* A darker tone for contrast */
  padding: 3rem;
  text-align: center;
}

/* Header title */
.event-header h1 {
  color: var(--gold-crayola);
  font-family: var(--fontFamily-forum);
  margin-bottom: 1rem;
}

/* Description */
.event-header p {
  color: var(--quick-silver);
  max-width: 800px;
  margin: 0 auto;
  line-height: var(--lineHeight-4);
}

/* Pricing section */
.pricing-section {
  color: var(--gold-crayola);
  font-size: var(--fontSize-body-2);
  margin: 2rem 0;
}

/* Book Now Button - Reusing your existing btn class */
.btn-primary {
  background-color: var(--gold-crayola);
  color: var(--smoky-black-1);
  padding: 1.2rem 2.4rem;
  border-radius: var(--radius-24);
  font-family: var(--fontFamily-dm_sans);
  font-size: var(--fontSize-label-2);
  letter-spacing: var(--letterSpacing-1);
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: var(--gold-crayola); /* A shade darker for hover effect */
  color: var(--white);
}

/* Event Footer - Reusing styles from the header */
.event-footer {
  background-color: var(--smoky-black-1);
  padding: 3rem;
  text-align: center;
}

/* Hero Section */
.event-hero {
  position: relative;
  text-align: center;
  color: var(--white);
}

.hero-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay for text readability */
}

/* Testimonials Section */
.testimonials-section {
  padding: 4rem 0;
  background-color: var(--eerie-black-2); /* Slight contrast from the main background */
  text-align: center;
}

.testimonial {
  color: var(--quick-silver);
  font-style: italic;
  margin-bottom: 1rem;
}

.testimonial-author {
  color: var(--gold-crayola);
  font-weight: bold;
}

/* Responsive styles for Hero Section */
@media (max-width: 768px) {
  .hero-content {
    position: static; /* Adjust position for mobile */
    transform: none;
    padding: 1rem;
    background-color: transparent; /* Optional: change or remove the background on mobile */
  }

  .hero-image {
    height: auto; /* Adjust height for mobile */
    object-fit: cover; /* Ensures the image covers the area without stretching */
  }

  /* Adjust font sizes for smaller screens */
  .event-hero h1 {
    font-size: var(--fontSize-title-2); /* Smaller font size */
  }

  .event-hero p {
    font-size: var(--fontSize-body-4); /* Smaller font size */
  }

  .pricing-section {
    font-size: var(--fontSize-label-1); /* Smaller font size */
  }

  /* Adjust the button size for smaller screens */
  .btn-primary {
    padding: 1rem 2rem;
    font-size: var(--fontSize-label-2);
  }
}

.gallery-section {
  padding: 80px 0;
}
.dish-card {
  padding: 20px;
  text-align: center;
}

.dish-card img {
  width: 100%;
  height: auto;
}

.container {
  width: 100%;
}

.zoomed-dish-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.zoomed-dish-overlay img {
  max-width: 70%;
  max-height: 70%;
}

.dish-name {
  font-family: var(--fontFamily-forum);
  color: white;
  font-size: 2rem;
  margin-top: 20px;
}

.dish-desc {
  font-family: var(--fontFamily-open_sans);
  color: white;
  font-size: 1.5rem;
  margin-top: 10px;
}

.dish-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* on mouse hover enlarge the image */
.dish-image:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}

.dish-image-zoomed {
  height: auto;
  border: 5px solid var(--gold-crayola);
}

.rec-arrow-left,
.rec-arrow-right {
  background-color: var(--gold-crayola) !important;
  color: var(--black) !important;
}

.header .btn {
  display: none;
}
.container {
  padding-inline: 16px;
}
.header_spacing {
  padding-block: 20px;
}
.logo {
  max-width: max-content;
  margin-inline: auto;
}
/* @media only screen and (max-width: 600px) {
  .logo {
    margin-block-end: 60px;
  }
} */
.navbar {
  position: fixed;
  background-color: var(--smoky-black-1);
  top: 0;
  left: -360px;
  bottom: 0;
  max-width: 360px;
  width: 100%;
  padding-inline: 30px;
  padding-block-end: 50px;
  overflow-y: auto;
  visibility: hidden;
  z-index: 2;
  transition: var(--transition-2);
}
.close-btn {
  color: var(--white);
  border: 1px solid currentColor;
  padding: 4px;
  border-radius: var(--radius-circle);
  margin-inline-start: auto;
  margin-block: 30px 20px;
}

.navbar {
  position: fixed;
  background-color: var(--smoky-black-1);
  top: 0;
  left: -360px;
  bottom: 0;
  max-width: 360px;
  width: 100%;
  padding-inline: 30px;
  padding-block-end: 50px;
  overflow-y: auto;
  visibility: hidden;
  z-index: 2;
  transition: var(--transition-2);
}

.header.active {
  background-color: rgba(33, 33, 33, 0.9) !important;
}

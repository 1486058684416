.section-subtitle {
  position: relative;
  color: var(--gold-crayola);
  font-weight: var(--weight-bold);
  text-transform: uppercase;
  letter-spacing: var(--letterSpacing-2);
  margin-block-end: 12px;
}
.testi {
  /* padding-block-end: 340px; */
  position: static;
}
.text-center {
  text-align: center;
}
.has-bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.container {
  padding-inline: 16px;
}
.quote {
  font-size: 6rem;
  font-family: var(--fontFamily-forum);
  line-height: 0.7em;
  color: var(--gold-crayola);
}
.testi-slider {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.testi-item {
  display: none; /* Hide all items initially */
}
.testi-item.active {
  display: block; /* Show active item */
}
.testi-text {
  font-size: 2rem;
  color: #ffffff;
  margin: 10px 0;
}
.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile .img {
  border-radius: 50%;
}
.profile-name {
  color: var(--gold-crayola);
}
.slider-btn {
  background: none;
  border: none;
  color: var(--gold-crayola);
  font-size: 2rem;
  cursor: pointer;
  margin: 10px;
}
.slider-btn.prev {
  float: left;
}
.slider-btn.next {
  float: right;
}
.wrapper .separator {
  width: 15px;
  height: 2px;
  background: var(--gold-crayola);
  margin: 5px;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero .slider-btn {
  display: none;
}
.text-center {
  text-align: center;
}
.slider-item {
  position: absolute;
  top: 50%;
  left: 50%;
}
.slider-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.label-2 {
  font-size: var(--fontSize-label-2);
}
.book-now {
  display: inline-block;
  padding: 0.5rem 1rem;
  border: 1px solid var(--color-primary);
  border-radius: 0.5rem;
  color: var(--color-primary);
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0.5rem;
}

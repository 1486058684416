.section-heading {
  margin: 20px; /* Margin on all sides */
  padding: 20px 0; /* Padding on top and bottom */
  text-align: center;
  position: relative; /* Needed for pseudo-elements */
  font-size: 40px !important;
}

.section-heading::before,
.section-heading::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 70%; /* Width of the border */
  height: 3px; /* Thickness of the border */
  background: linear-gradient(to right, gold, goldenrod, gold); /* Gradient effect */
}

.section-heading::before {
  top: 0;
}

.section-heading::after {
  bottom: 0;
}

.section-heading h3 {
  color: white; /* White font color */
  margin: 0;
  padding: 0;
  font-weight: bold;
  text-transform: uppercase;
  position: relative; /* Ensure the text appears above the pseudo-elements */
  z-index: 1; /* Higher than pseudo-elements */
}

.topbar {
  display: none;
}
.container {
  padding-inline: 16px;
}
.topbar-item:not(.link),
.topbar .separator {
  display: none;
}
.icon-wrapper {
  position: relative;
  margin-block-end: 20px;
}
.span {
  transition: var(--transition-1);
}
.separator {
  width: 8px;
  height: 8px;
  border: 1px solid var(--gold-crayola);
  transform: rotate(45deg);
}
.link {
  display: inline;
  color: var(--gold-crayola);
}
.item-2 {
  margin-inline-end: auto;
}
.icon {
  width: 24px;
  height: 24px;
  fill: var(--gold-crayola);
}

.features .section-title {
  margin-block-end: 40px;
}
.feature-item:nth-child(2n + 1) .feature-card {
  background-color: var(--eerie-black-3);
}
.feature-card {
  padding: 30px 20px 40px;
}
.card-icon {
  max-width: max-content;
  margin-inline: auto;
  transition: var(--transition-2);
}
.card-title {
  margin-block-end: 12px;
}
.card-text {
  color: var(--quick-silver);
  line-height: var(--lineHeight-4);
}

@media screen and (min-width: 768px) {
  .reservation-section-wrapper {
    padding-top: 80px;
  }
}

.form-text {
  margin-block-end: 40px;
}
.bg-black-10 {
  background-color: var(--smoky-black-2);
}
.form-left,
.form-right {
  padding: 40px 20px 30px;
}
.headline-1 {
  color: var(--white);
  font-family: var(--fontFamily-forum);
  font-weight: var(--weight-regular);
  line-height: var(--lineHeight-2);
}
.text-center {
  text-align: center;
}
.link {
  display: inline;
  color: var(--gold-crayola);
}
/* More styles */
